<template>
  <div id="app">
    <HeaderView></HeaderView>
    <router-view/>
    <FooterView></FooterView>
  </div>
</template>
<script>
import HeaderView  from "../src/components/Header.vue";
import FooterView  from "../src/components/Footer.vue";
export default {
    components: {
      HeaderView,
      FooterView
    }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html body{
  margin: 0;
  padding: 0;
}

</style>
