<template>
  <div class="home">
    <div class="banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
            <img class="swiper-slide" src="../assets/banner3.jpg" alt="">
            <img class="swiper-slide" src="../assets/banner4.jpg" alt="">
        </div>
      </div>
      <div class="banner-data maxwidth">
        <div class="data-box">
          <div class="top pink">{{data1}}年</div>
          <div class="text">互联网行业经验/年</div>
        </div>
        <div class="data-box">
          <div class="top green">{{data2}}+</div>
          <div class="text">营销服务团队</div>
        </div>
        <div class="data-box">
          <div class="top yellow">{{data3}}%</div>
          <div class="text">主流媒体覆盖率</div>
        </div>
        <div class="data-box">
          <div class="top bule">{{data4}}+</div>
          <div class="text">客户的信任与选择</div>
        </div>
      </div>
    </div>
    <div class="four-lb">
      <div class="first">五大流量级平台，日均曝光20亿！</div>
      <div class="twoed">PLATFORM STRENGTH</div>
      <div class="secdend">为您提供"品牌策划、媒体投放、效果评估"等一站式整合营销服务</div>
    </div>
    <div class="four-banner maxwidth">
      <div class="four-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
              <div class="left"><img src="../assets/choose.jpg" alt=""></div>
              <div class="right">
                <img src="../assets/douyin.jpg" alt="">
                <div class="right-bottom">
                  一个旨在帮助大众用户表达自我，记录美好生活的短视频社交平台。应用人工智能技术为用户创造丰富多样的玩法，让用户在生活中轻松快速产出优质短视频。自上线以来，一直占领应用下载榜首，全球MAU5亿+，DAU1.5亿，日均视频播放量200亿，覆盖150个国家和地区
                </div>
              </div>
          </div>
          <div class="swiper-slide">
              <div class="left"><img src="../assets/da3.jpg" alt=""></div>
              <div class="right">
                <img src="../assets/q1.jpg" alt="">
                <div class="right-bottom">
                  今日头条现在已经成为一个通过人工智能技术，给用户推荐信息的超级内容平台。今日头条单产品日均使用时长超76分钟，日均启动约9次。	今日头条整个海外目前已经超过3000万日活跃用户，在走向全球方面，取得了一定成绩。
                </div>
              </div>
          </div>
          <div class="swiper-slide">
              <div class="left"><img src="../assets/da2.jpg" alt=""></div>
              <div class="right">
                <img src="../assets/q5.jpg" alt="">
                <div class="right-bottom">
                  支付宝万家灯火广告系统通过支付宝平台，分析手机用户的线下行为轨迹，在结合用户在手机的浏览记录、爱好取向，帮助商家广告主筛选出高精准的目标客户，针对目标客户进行精准广告投放的。如CPC、CPA精准投放。
                </div>
              </div>
          </div>
          <div class="swiper-slide">
              <div class="left"><img src="../assets/da1.jpg" alt=""></div>
              <div class="right">
                <img src="../assets/q3.jpg" alt="">
                <div class="right-bottom">
                  快手是北京快手科技有限公司旗下的产品，快手的宣传语:看见每一种生活，拥抱每一种生活。记录世界，记录每个普普通通的人，都会有伟大的成就。快手平均日活跃用户数达3.63亿，快手应用累计互关用户数达235亿。
                </div>
              </div>
          </div>
          <div class="swiper-slide">
              <div class="left"><img src="../assets/da4.jpg" alt=""></div>
              <div class="right">
                <img src="../assets/q4.jpg" alt="">
                <div class="right-bottom">
                  腾讯广告是国内领先的大数据营销平台，汇聚腾讯公司全量的应用场景，基于海量商业数据、营销技术与专业服务能力，提供一体化数字化营销方案。腾讯广告构建品牌与用户的美好连接，为客户提供高效的营销解决方案。
                </div>
              </div>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <div class="four-lb martop">
      <div class="first">产品服务</div>
      <div class="twoed">PRODUCTS SERVICES</div>
      <div class="secdend">为您提供"品牌策划、媒体投放、效果评估"等一站式整合营销服务</div>
    </div>
    <div class="boxes maxwidth">
      <div class="single-box" v-for="(item,index) in boxesList" :key="index">
        <div class="part-icon">
          <img :src="item.img" alt="">
        </div>
        <div class="part-txt">{{item.txt}}</div>
      </div>
    </div>
    <div class="four-lb martop">
      <div class="first">我们的优势</div>
      <div class="twoed">OUR ADVANTAGES</div>
      <div class="secdend">专业的“效果、品牌、流量”移动营销解决方案</div>
    </div>
    <div class="testimonial">
      <div class="test-swiper maxwidth">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in testList" :key="index">
            <div class="icon">
              <img :src="item.img1" alt="" class="show">
              <img :src="item.img2" alt="" class="isshow">
            </div>
            <div class="title">{{item.txt1}}</div>
            <div class="txt">{{ item.txt2 }}</div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="four-lb martop">
      <div class="first">案例</div>
      <div class="twoed">CASE</div>
      <div class="secdend">专业的“效果、品牌、流量”移动营销解决方案</div>
    </div>
    <div class="testimonial case-box">
      <div class="case-swiper maxwidth">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in caseList" :key="index">
            <div class="icon">
              <img :src="item.img1" alt="" class="show">
            </div>
            <div class="title">{{item.txt1}}</div>
            <div class="txt">{{ item.txt2 }}</div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="four-lb martop">
      <div class="first">关于壹点通</div>
      <div class="twoed">ABOUT XUANRUN</div>
    </div>
    <div class="about maxwidth">
      <div class="left">
        <img src="../assets/about.jpg" alt="">
      </div>
      <div class="right">
        <div class="r-top">壹点通是一家基于互联网，以精准结果为主导的实效广告传播公司。自2018年成立以来，专注于“让广告更精准有效”为技术前驱，以庞大的专业团队支撑，为广告主提供优质高效的精准营销。公司以广州为起点，辐射全国业务枢纽，拥有数十人的专业营销服务团队，累计服务大中华地区过千家企业，拥有行业内最资深、专业的互联网效果广告运营团队，业务涵盖了抖音、快手、天猫、京东与腾讯各大主流平台的广告服务。以务实营销、精准营销、增长营销为主导。依托于丰富的互联网媒介资源，优质的客商服务，已荣幸成为国内众多知名企业的的广告服务商。</div>
        <!-- <div class="r-bottom">壹点通网络作为国内领先的互联网营销整体解决方案服务商，在依托支付宝、腾讯、抖音、快手等互联网媒体平台，也成为主流媒体战略合作伙伴多个领域产品代理托管运营。全方位为广大客户提供“精准、高效、价值”的一站式互联网营销解决方案。公司的愿景是“让企业没有难做的广告”，我们的定位是“营销一点通，推广更轻松”，秉承“善护念，厚积德，多宽容，常感恩，信因果”的价值观，创一流服务，服务了数十个投放量日消耗超100万客户，得到了客户的认可与肯定。</div> -->
      </div>
    </div>
    <div class="four-lb martop">
      <div class="first">合作伙伴</div>
      <div class="twoed">COOPERATIVE PARTNER</div>
    </div>
    <div class="cooperate maxwidth">
      <div class="box" v-for="(item,index) in cooperateList" :key="index">
        <div class="img-box">
          <img :src="item.img" alt="">
        </div>
      </div>
    </div>
    <div class="top-btn" @click="topClick(5,20)" v-show="istop">
      <img src="../assets/top.png" alt="">
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      data1:0,
      data2:0,
      data3:0,
      data4:0,
      timer1:null,
      timer2:null,
      timer3:null,
      timer4:null,
      boxesList:[
        {
          img:require('../assets/cv1.png'),
          txt:'广告策划'
        },
        {
          img:require('../assets/cv2.png'),
          txt:'广告分析'
        },
        {
          img:require('../assets/cv3.png'),
          txt:'广告投放'
        },
        {
          img:require('../assets/cv4.png'),
          txt:'供应链提供'
        },
        {
          img:require('../assets/cv5.png'),
          txt:'视频拍摄'
        },
        {
          img:require('../assets/cv6.png'),
          txt:'社交信息流广告'
        },
        // {
        //   img:require('../assets/cv7.png'),
        //   txt:'家居装修'
        // },
        // {
        //   img:require('../assets/cv8.png'),
        //   txt:'品牌推广'
        // }
      ],
      testList:[
        {
          img1:require('../assets/j7.png'),
          img2:require('../assets/j77.png'),
          txt1:'智能广告投放',
          txt2:'广告投放全程系统监控 自动调优策略与投放细节 智能提升广告效果',
        },
        {
          img1:require('../assets/j8.png'),
          img2:require('../assets/j88.png'),
          txt1:'一键轻松投放',
          txt2:'输入多项系统内置营销 参数，即可自动生成优秀广告投 放策略并执行投放',
        },
        {
          img1:require('../assets/j9.png'),
          img2:require('../assets/j99.png'),
          txt1:'数据监测与回收',
          txt2:'集成大量数据分析与挖掘工具销售线索管理工具,业务协同工具等',
        },
        {
          img1:require('../assets/j10.png'),
          img2:require('../assets/j100.png'),
          txt1:'云计算集成',
          txt2:'有机整合权威第三方及 多年沉淀的海量用户数据 打造适合中国营销环节的DMP',
        },
        {
          img1:require('../assets/j11.png'),
          img2:require('../assets/j111.png'),
          txt1:'数据监测与回收',
          txt2:'有机整合权威第三方及 多年沉淀的海量用户数据 打造适合中国营销环节的DMP',
        },
      ],
      caseList:[
        {
          img1:require('../assets/k1.png'),
          txt1:'海尔智能家居',
          txt2:'基于产品特性，对投放人群仅做年龄、地区设置，多素材测试+知名人士品牌背书，重点突出产品用途，公司年限发展规模等，高效的渗透品牌信息',
        },
        {
          img1:require('../assets/k2.png'),
          txt1:'智能可穿戴健康品牌-SKG',
          txt2:'基于产品特性，对投放人群仅做年龄、地区设置，多素材测试+知名人士品牌背书，重点突出产品用途，公司年限发展规模等，高效的渗透品牌信息',
        },
        {
          img1:require('../assets/k3.png'),
          txt1:'广州法素茜生物科技有限公司',
          txt2:'基于产品特性，对投放人群仅做年龄、地区设置，多素材测试+知名人士品牌背书，重点突出产品用途，公司年限发展规模等，高效的渗透品牌信息',
        },
        {
          img1:require('../assets/k4.png'),
          txt1:'立白',
          txt2:'基于产品特性，对投放人群仅做年龄、地区设置，多素材测试+知名人士品牌背书，重点突出产品用途，公司年限发展规模等，高效的渗透品牌信息P',
        },
        // {
        //   img1:require('../assets/k5.jpg'),
        //   txt1:'陆金所',
        //   txt2:'基于产品特性，对投放人群仅做年龄、地区设置，多素材测试+知名人士品牌背书，重点突出产品用途，公司年限发展规模等，高效的渗透品牌信息',
        // },
        // {
        //   img1:require('../assets/k6.jpg'),
        //   txt1:'光明菜园',
        //   txt2:'基于产品特性，对投放人群仅做年龄、地区设置，多素材测试+知名人士品牌背书，重点突出产品用途，公司年限发展规模等，高效的渗透品牌信息',
        // },
      ],
      cooperateList:[
        {
          img:require('../assets/l1.png')
        },
        {
          img:require('../assets/l2.png')
        },
        {
          img:require('../assets/l3.png')
        },
        {
          img:require('../assets/l4.png')
        },
        {
          img:require('../assets/l5.png')
        },
        {
          img:require('../assets/l6.png')
        },
        {
          img:require('../assets/l7.jpg')
        },
        {
          img:require('../assets/l8.jpg')
        },
      ],
      num:0,
      scrollNum:0,
      istop:false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    this.timer1 = setInterval(()=>{
      this.data1++
      if(this.data1==5){
        clearInterval(this.timer1)
      }
    },200)
    this.timer2 = setInterval(()=>{
      this.data2+=350
      if(this.data2==9800){
        this.data2=10000
        clearInterval(this.timer2)
      }
    },30)
    this.timer3 = setInterval(()=>{
      this.data3+=2
      if(this.data3==90){
        clearInterval(this.timer3)
      }
    },22)
    this.timer4 = setInterval(()=>{
      this.data4+=3050
      if(this.data4==48800){
        this.data4 = 50000
        clearInterval(this.timer4)
      }
    },60)
    new Swiper('.swiper-container', {
      autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
      },
      loop:true
    })
    new Swiper('.four-swiper', {
      loop:true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    new Swiper('.test-swiper', {
      slidesPerView: 3,
      spaceBetween: 0,
      loop:true,
      slidesPerView: 'auto',
      loopFillGroupWithBlank: true,
      observer:true,
      observeParents:true,
      pagination:{
        el:'.swiper-pagination',
        bulletElement : 'div',
        clickable :true,
      },
    })
    new Swiper('.case-swiper', {
      slidesPerView: 3,
      spaceBetween: 0,
      loop:true,
      slidesPerView: 'auto',
      loopFillGroupWithBlank: true,
      observer:true,
      observeParents:true,
      pagination:{
        el:'.swiper-pagination',
        bulletElement : 'div',
        clickable :true,
      },
    })
  },
  watch:{
  },
  methods:{
    handleScroll(){
      this.scrollNum = document.documentElement.scrollTop || document.body.scrollTop
      if(this.scrollNum>300){
        this.istop = true
      }else{
        this.istop = false
      }
    },
    topClick(i,time){
       //参数i表示间隔的幅度大小，以此来控制速度
      document.documentElement.scrollTop-=i;
      if (document.documentElement.scrollTop>0) {
        var c=setTimeout(()=>{
          if(this.num>35){
            this.topClick(130,20)
            this.num++
          }else{
            this.topClick(i,20)
            this.num++
          }
        },time);
      }else {
        this.num=0
        clearTimeout(c);
      }
    }
  },
}
</script>
<style scoped lang="scss">
.home{
  .banner{
    width: 100%;
    position: relative;
    .swiper-container{
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      .swiper-wrapper{
        display: flex;
        flex-wrap: nowrap;
        .swiper-slide{
          min-width: 100%;
          .img{
            min-width: 100%;
          }
        }
      }
    }
    .banner-data{
      width: 94%;
      display: flex;
      align-items: center;
      padding-top: 50px;
      padding-bottom: 50px;
      background-color: #FFFFFF;
      box-shadow: 0px 7px 23px rgba(0, 0, 0, 0.03);
      border-radius: 16px;
      position: absolute;
      left: 50%;
      bottom: -66px;
      transform: translateX(-50%);
      .data-box{
        width: 25%;
        color: #000000;
        text-align: center;
        border-right: 1px solid rgba(200,200,200,0.25);

        .top{
          font-size: 48px;
          font-weight: 600;
          margin-bottom: 30px;
        }
        .pink{
          color: #FFAAA8;
        }
        .green{
          color: #00A537;
        }
        .yellow{
          color: #FFB300;
        }
        .bule{
          color: #003CFF;
        }
        
        .text{
          font-size: 23px;
          font-weight: 400;
        }
      }
    }
  }
  .four-lb{
    width: 100%;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 10px;
    line-height: 36px;
    .first{
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 44px;
      color: #1D2860;
    }
    .twoed{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #B5B5B5;
    }
    .secdend {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #CFCFCF;
    }
  }
  .martop{
    margin-top: 20px;
  }
  .four-banner{
    width: 94%;
    margin: 0 auto;
    .four-swiper{
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      .swiper-wrapper{
        display: flex;
        flex-wrap: nowrap;
        .swiper-slide{
          min-width: 100%;
          display: flex;
          align-items: center;
          .left{
            width: 50%;
            padding: 0 15px;
            img{
              width: 96%;
            }
          }
          .right{
            width: 41%;
            padding: 0 15px 160px;
            img{
              width: 96%;
            }
            .right-bottom{
              font-size: 21px;
              color: #000000;
              line-height: 2;
              font-weight: 500;
              text-align: left;
              text-indent: 2em;
            }
          }
        }
      }
      .swiper-button-prev{
        background: url('../assets/left.png') no-repeat left top;
        position: absolute;
        left: 10px;
        top: 50%;
        // margin-top: -15px;
        width: 33px;
        height: 33px;
        z-index: 999;
        cursor: pointer;
      }
      .swiper-button-next{
        background: url('../assets/right.png') no-repeat left bottom;
        position: absolute;
        right: 10px;
        top: 50%;
        // margin-top: -44px;
        width: 33px;
        height: 33px;
        z-index: 999;
        cursor: pointer;
      }
    }
  }
  .boxes{
    width: 94%;
    margin: 80px auto 60px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    .single-box{
      width: 20%;
      .part-icon{
        border: 3px solid #556FDD !important;
        text-align: center;
        width: 124px;
        height: 124px;
        line-height: 110px;
        background: #fff;
        border-radius: 50%;
        margin:0 auto 40px;
        img{
          max-width: 100%;
          margin-top: 36px;
          transition: All 1.5s ease-in-out;
          -webkit-transition: All 1.5s ease-in-out;
        }
      }
      .part-icon:hover{
        img{
          -webkit-transform: rotateY(180deg);
          transform: rotateY(720deg);
        }
      }
      .part-txt{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        text-align: center;
      }
    }
  }
  .testimonial{
    width: 100%;
    padding-bottom: 120px;
    padding-top: 120px;
    background-color: #F2F3F8;
    margin-bottom: 80px;
    .test-swiper,.case-swiper{
      width: 94%;
      overflow-x: hidden;
      margin: 0 auto;
      position: relative;
      padding: 10px 10px 50px;
      .swiper-wrapper{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        .swiper-slide{
          min-width: 422px;
          height: 402px;
          margin-right: 66px;
          background-color: #fff;
          text-align: center;
          padding: 50px 30px 30px;
          // transition: all 0.4s;
          border-radius: 20px;
          box-shadow: 0px 1px 23px rgba(0, 0, 0, 0.04);
          box-sizing: border-box;
          cursor: pointer;
          .icon{
            width: 80px;
            height: 80px;
            margin: 0 auto 50px;
            .show{
              width: 75%;
              display: block;
            }
            .isshow{
              width: 75%;
              display: none;
            }
          }
          .title{
            font-size: 25px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-bottom: 25px;
          }
          .txt{
            line-height: 26px;
            margin-bottom: 19px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #CBCBCB;
          }
          &:hover{
            background: linear-gradient(-60deg, #556FDD 50%, #556FDD );
            animation-duration: 0.4s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-name: bgDown;
            animation-name: bgDown;
            // border-radius: 20px;
            .show{
              display: none;
            }
            .isshow{
              display: block;
            }
            .title,.txt{
              color: #fff;
            }
          }
          @keyframes bgDown {
              0%{
                  border-radius: 0;
              }
              100%{
                  border-radius: 20px;
              }
          }
        }
      }
      .swiper-pagination{
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
      }
    }
  }
  .case-box{
    background: #fff;
    padding-bottom: 0;
    .case-swiper{
      .swiper-wrapper{
        .icon{
          width: 130px !important;
        }
        .show{
          width: 130px !important;
          height: 80px;
          transition: all 1.6s;
          &:hover{
            transform: scale(1.6);
          }
        }
        .swiper-slide:hover{
          animation:none !important;
          background: #fff !important;
          .title{
              color: #000000;
          }
          .txt{
            color: #CBCBCB;
          }
          .show{
              display: block;
            }
        }
      }
    }
  }
  .about{
    width: 94%;
    margin: 60px auto;
    display: flex;
    .left{
      width: 50%;
      padding: 0 20px 0 0;
      img{
        max-width: 100%;
      }
    }
    .right{
      width: 50%;
      padding: 20px 5px 0;
      .r-top{
        font-size: 21px;
        color: #000000;
        line-height: 2;
        font-weight: 500;
        text-align: left;
        text-indent: 2em;
      }
      .r-bottom{
        font-size: 21px;
        color: #000000;
        line-height: 2;
        font-weight: 500;
        text-align: left;
        text-indent: 2em;
      }
    }
  }
  .cooperate{
    width: 94%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 100px auto 0;
    .box{
      width: 25%;
      height: 124px;
      margin-bottom: 40px;
      text-align: center;
      .img-box{
        width: 124px;
        height: 40px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
        border-radius: 32px;
        padding: 10px;
        margin: 0 auto;
      }
      img{
        width: 124px;
        height: 40px;
        border-radius: 32px;
      }
    }
  }
  .top-btn{
    position: fixed;
    right: 10px;
    bottom: 30px;
    z-index: 999999;
    img{
      max-width: 100%;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px){
  .home{
    overflow-x: hidden;
    .banner{
      .banner-data{
        padding-bottom: 10px;
        padding-top: 14px;
        border-radius: 10px;
        bottom: -60px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        .data-box{
          .top{
            font-size: 24px;
            margin-bottom: 10px;
          }
          .text{
            font-size: 12px;
            height: 32px;
          }
        }
      }
    }
    .four-lb{
      .first{
        font-size: 20px;
        line-height: 30px;
      }
      .twoed{
        line-height: 24px;
      }
      .secdend{
        display: none;
      }
    }
    .four-banner{
      .four-swiper{
        .swiper-wrapper{
          .swiper-slide{
            .left{
              display: none;
            }
            .right{
              width: 100%;
              text-align: center;
              padding-bottom: 35px;
              img{
                width: 120px;
              }
              .right-bottom{
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .boxes{
      .single-box{
        width: 33%;
        margin-bottom: 15px;
        .part-icon{
          width: 70px;
          height: 70px;
          line-height: 70px;
          margin-bottom: 10px;
          img{
            width: 35px;
            margin-top: 16px;
          }
        }
        .part-txt{
          font-size: 16px;
          height: 42px;
        }
      }
    }
    .testimonial{
      text-align: center;
      padding: 20px 0 36px;
      .test-swiper,.case-swiper{
        .swiper-wrapper{
          .swiper-slide{
            min-width: 108px;
            margin-right: 10px;
            text-align: center;
            padding: 25px 0 20px;
            height: auto;
            .icon{
              width: 60px;
              height: 40px;
              margin-bottom: 10px;
              img{
                width: auto !important;
                margin: 0 auto !important;
                max-height: 40px;
                max-width: 40px;
              }
            }
            .title{
              font-size: 14px;
            }
            .txt{
              font-size: 12px;
              padding: 0 10px;
              height: 70px;
              overflow: hidden;
            }
          }
        }
      }
    }
    .case-box{
      .case-swiper{
        .swiper-wrapper{
          .swiper-slide{
            .icon{
              width: 110px !important;
              height: 70px;
              margin-bottom: 14px;
              img{
                width: 80px !important;
                height: auto !important;
                max-width: 80px;
                max-height: 80px;
              }
            }
            .title{
              height: 40px;
            }
          }
        }
      }
    }
    .about{
      display: block;
      .left{
        width: 100%;
      }
      .right{
        width: 100%;
        .r-top{
          font-size: 16px;
        }
      }
    }
    .cooperate{
      margin-top: 10px;
      .box{
        width: 33%;
        height: 60px;
        margin-bottom: 10px;
        .img-box{
          width: 90px;
          height: 40px;
          box-shadow: none;
          img{
            max-width: 100%;
            height: 30px;
          }
        }
      }
    }
  }
}
</style>
<style>
.swiper-pagination-bullet{
  width: 22px;
  height: 4px;
  background: #E6E6E6;
  cursor: pointer;
  margin-right: 3px;
}
.swiper-pagination-bullet-active{
  background: #556FDD;
}
@media only screen and (min-width: 1200px){
  .maxwidth {
      width: 1450px !important;
  }
}
</style>
