import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/index',
    name: 'Home',
    component: () => import( '../views/HomeView.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import( '../views/service.vue')
  },
  {
    path: '/service-details',
    name: 'serviceDetails',
    component: () => import( '../views/serviceDetails.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import( '../views/faq.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/contact.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/about.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import( '../views/join.vue')
  },
  {
    path: '/zfb',
    name: 'zfb',
    component: () => import( '../views/zfb.vue')
  },
  {
    path: '/tencent',
    name: 'tencent',
    component: () => import( '../views/tencent.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	window.scrollTo(0,0)
  return originalPush.call(this, location).catch(err => err)
}

export default router
