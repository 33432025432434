<template>
    <div class="header" :class="isscroll&&'fixed-header'">
        <div class="container">
            <div class="logo-box">
                <div class="logo" @click="routerUrl('index')"><img src="../assets/logo.png" alt=""></div>
                <div class="title">广州壹点通网络科技有限公司</div>
                <div class="phone-icon" @click="ClickMenu"></div>
                <div class="iphon-nav" :class="ismenu&&'show-menu'">
                    <div class="ul">
                        <div class="li" @click="routerUrl('index')">首页</div>
                        <div class="li select">
                            <span @click="iphone">投放平台</span>
                            <div class="dropdown-menu" v-if="menuActive">
                                <div class="menu" @click="routerUrl('service')">巨量广告</div>
                                <div class="menu" @click="routerUrl('service-details')">快手磁力金牛</div>
                                <div class="menu" @click="routerUrl('tencent')">腾讯广点通</div>
                                <div class="menu" @click="routerUrl('zfb')">支付宝灯火</div>
                            </div>
                        </div>
                        <div class="li" @click="routerUrl('faq')">投放案例</div>
                        <div class="li" @click="routerUrl('contact')">联系我们</div>
                        <div class="li" @click="routerUrl('about')">关于壹点通</div>
                        <div class="li" @click="routerUrl('join')">加入我们</div>
                    </div>
                </div>
            </div>
            <div class="nav-box">
                <div class="ul">
                    <div class="li" @click="routerUrl('index')">首页</div>
                    <div class="li select">
                        <span>投放平台</span>
                        <div class="dropdown-menu">
                            <div class="menu" @click="routerUrl('service')">巨量广告</div>
                            <div class="menu" @click="routerUrl('service-details')">快手磁力金牛</div>
                            <div class="menu" @click="routerUrl('tencent')">腾讯广点通</div>
                            <div class="menu" @click="routerUrl('zfb')">支付宝灯火</div>
                        </div>
                    </div>
                    <div class="li" @click="routerUrl('faq')">投放案例</div>
                    <div class="li" @click="routerUrl('contact')">联系我们</div>
                    <div class="li" @click="routerUrl('about')">关于壹点通</div>
                    <div class="li" @click="routerUrl('join')">加入我们</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HeaderView',
    components: {
    },
    data(){
        return {
            isscroll:false,
            menuActive:false,
            ismenu:false
        }
    },
    mounted(){
        window.onscroll = ()=> {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if(scrollTop>300){
                this.isscroll = true
            }else{
                this.isscroll = false
            }
        }
    },
    methods:{
        routerUrl(url){
            this.ismenu = false
            this.menuActive = false
            this.$router.push({
                path:url,
            });
        },
        iphone(){
            this.menuActive = !this.menuActive
        },
        ClickMenu(){
            this.ismenu = !this.ismenu
            if(!this.ismenu){
                this.menuActive = false
            }
        }
    }
}
</script>
<style scoped lang="scss">
.header{
    width: 100%;
    .container{
        width: 94%;
        margin: 0 auto;
        padding: 0 15px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        .logo-box{
            width: 33%;
            display: flex;
            align-items: center;
            .logo{
                height: 54px;
                cursor: pointer;
                img{
                    height: 54px;
                }
            }
            .title{
                margin-left: 15px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #010101;
            }
            .phone-icon{
                display: none;
            }
            .iphon-nav{
                display: none;
            }
        }
        .nav-box{
            width: 66%;
            .ul{
                display: flex;
                align-items: center;
                justify-content: center;
                .li{
                    cursor: pointer;
                    padding: 40px 24px;
                    font-size: 18px;
                    line-height: 100%;
                    font-weight: 400;
                    color: #1D2860;
                    font-family: Microsoft YaHei;
                    &:hover{
                        color: #ff5e19;
                    }
                    position: relative;
                    .dropdown-menu{
                        width: 220px;
                        box-shadow: 0 10px 30px 0 rgba(25, 25, 25, .15);
                        opacity: 0;
                        height: 0;
                        -webkit-transition: all .3s ease-in;
                        -o-transition: all .3s ease-in;
                        transition: all .3s ease-in;
                        position: absolute;
                        top: 69%;
                        left: 0;
                        z-index: 2;
                        background-color: #fff;
                        overflow-y: hidden;
                        .menu{
                            width: 100%;
                            font-weight: 400;
                            color: #212529;
                            font-size: 16px;
                            line-height: 100%;
                            font-weight: 600;
                            padding: 20px;
                            box-sizing: border-box;
                            border-bottom: 1px solid rgba(25, 25, 25, .1);
                            &:hover{
                                color: #ff5e19;
                            }
                        }
                    }
                }
                .select:hover{
                    .dropdown-menu{
                        height: 228px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.fixed-header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    -webkit-box-shadow: 0 10px 20px 0 rgba(25, 25, 25, .1);
    box-shadow: 0 10px 20px 0 rgba(25, 25, 25, .1);
    background: #fff;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    .container{
        .nav-box{
            .ul{
                .li{
                    padding: 27px 24px;
                }
            }
        }
    }
}
@keyframes fadeInDown {
    0%{
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100%{
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@media only screen and (min-width: 320px) and (max-width: 479px){
    .fixed-header{
        box-shadow: none;
    }
    .header{
        background: #a8c9fe;
        padding: 10px 0px;
        box-sizing: border-box;
        .container{
            .logo-box{
                width: 100%;
                justify-content: space-between;
                position: relative;
                .title{
                    display: none;
                }
                .phone-icon{
                    display: block;
                    width: 27px;
                    height: 27px;
                    background: url('../assets/More.png');
                    background-repeat: no-repeat;
                    background-size: 100%;
                    margin-right: 15px;
                }
                .iphon-nav{
                    width: calc(100% + 56px);
                    display: block;
                    position: absolute;
                    top: 64px;
                    left: -30px;
                    z-index: 99;
                    background: #a8c9fe;
                    padding: 10px 0px;
                    box-sizing: border-box;
                    opacity: 0;
                    height: 0;
                    -webkit-transition: all .3s ease-in;
                    -o-transition: all .3s ease-in;
                    transition: all .3s ease-in;
                    .ul{
                        width: calc(100% - 56px);
                        opacity: 0;
                        height: 0;
                        -webkit-transition: all .3s ease-in;
                        -o-transition: all .3s ease-in;
                        transition: all .3s ease-in;
                        overflow-y: scroll;
                        padding: 15px;
                        box-sizing: border-box;
                        background-color: #fff;
                        margin: 0 auto;
                        .li{
                            width: 100%;
                            padding: 12px 0 13px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #1d2860;
                            &:hover{
                                color: #ff5e19;
                            }
                            .dropdown-menu{
                                margin-top: 10px;
                                box-shadow: 0 0 20px 0 rgba(25, 25, 25, .15);
                                .menu{
                                    padding: 15px 20px !important;
                                    font-size: 14px;
                                    line-height: 100%;
                                    font-weight: 600;
                                    color: #454545;
                                    padding: 0 20px;
                                    border-bottom: 1px solid rgba(25, 25, 25, .1);
                                    &:hover{
                                        color: #ff5e19;
                                    }
                                }
                            }
                        }
                    }
                }
                .show-menu{
                    opacity: 1;
                    height: 340px;
                    .ul{
                        opacity: 1;
                        height: 320px;
                    }
                }
            }
            .nav-box{
                display: none;
            }
        }
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px){
    .header{
        .container{
            .nav-box{
                .ul{
                    .li{
                        padding: 40px 15px;
                    }
                }
            }
        }
    }
    
}
</style>