<template>
    <div class="footer">
        <div class="top">
            <div class="logo-box">
                <img src="../assets/logo.png" alt="">
                <div class="text">广州壹点通网络科技有限公司</div>
            </div>
            <div class="two-box no-iphone">
                <div class="title">
                    <img src="../assets/z1.png" alt="">
                    <div>服务</div>
                </div>
                <div class="text2">服饰</div>
                <div class="text2">百货</div>
                <div class="text2">化妆品</div>
                <div class="text2">3C数码</div>
                <div class="text2">食品</div>
                <div class="text2">生活服务</div>
				<div class="text2">家居装修</div>
				<div class="text2">品牌推广</div>
            </div>
            <div class="two-box two-box2">
                <div class="title">
                    <img src="../assets/z2.png" alt="">
                    <div>联系我们</div>
                </div>
                <div class="text2">18818809714</div>
                <div class="text2">180022750@qq.com</div>
                <div class="text2">广州市白云区永平街白云</div>
                <div class="text2">大道北888号B1503房</div>
            </div>
            <div class="two-box margin1">
                <div class="title title2">
                    <img src="../assets/z3.png" alt="">
                    <div>关注我们</div>
                </div>
                <div class="code">
                    <img src="../assets/wei.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="bottom">
            <div>Copyright © 2021-长期 广州壹点通网络科技有限公司</div>
            <div>Powered by www.1dton.com <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023115207号</a></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FooterView',
    components: {
    },
    data(){
        return {

        }
    },
    mounted(){
        
    }
}
</script>
<style scoped lang="scss">
.footer{
    width: 100%;
    padding: 20px 0;
    background: #a8c9fe;
    .top{
        width: 94%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .logo-box{
            width: 25%;
            margin-bottom: 56px;
            padding: 0 15px;
            img{
                width: 142px;
                margin-bottom: 31px;
                margin-left: 21px;
            }
            .text{
                line-height: 30px;
                color: #000;
                font-size: 21px !important;
                font-family: Microsoft YaHei;
                font-weight: 400;
            }
        }
        .two-box{
            width: 16.6%;
            padding-top: 40px;
            margin-left: 10px;
            .title{
                width: 100%;
                display: flex;
                align-items: flex-end;
                img{
                    max-width: 30px;
                    margin-right: 2px;
                }
                div{
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000;
                    margin-left: 2px;
                }
            }
            .title2{
                justify-content: center;
            }
            .text2{
                font-size: 17px;
                line-height: 34px;
                color: #000;
                padding-left: 34px;
            }
            .code{
                width: 131px;
                height: 131px;
                margin: 20px auto 0;
                img{
                    max-width: 100%;
                }
            }
        }
        .margin1{
            margin-left: 28px;
        }
    }
    .bottom{
        margin-top: 56px;
        width: 100%;
        div{
            text-align: center;
            font-size: 16px;
            color: #000;
            margin-bottom: 5px;
            a{
                color: #007bff;
                cursor: pointer;
                transition: all 0.4s;
                text-decoration: none;
                background-color: transparent;
                &:hover{
                    color: #0056b3;
                }
            }
        }
    }
}
@media only screen and (min-width: 320px) and (max-width: 479px){
    .footer{
        .top{
            width: 100%;
            .logo-box{
                display: none;
            }
            .no-iphone{
                display: none;
            }
            .two-box{
                width: 44%;
                padding-top: 20px;
                .title{
                    img{
                        width: 23px;
                    }
                    div{
                        font-size: 20px;
                    }
                }
                .text2{
                    font-size: 15px;
                }
            }
            .two-box2{
                width: 56%;
            }
            .margin1{
                margin-left: 0;
            }
        }
        .bottom{
            margin-top: 28px;
            div{
                font-size: 14px;
            }
        }
    }
}
</style>